// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-ts": () => import("./../../../src/pages/404.ts" /* webpackChunkName: "component---src-pages-404-ts" */),
  "component---src-pages-blog-ts": () => import("./../../../src/pages/blog.ts" /* webpackChunkName: "component---src-pages-blog-ts" */),
  "component---src-pages-index-ts": () => import("./../../../src/pages/index.ts" /* webpackChunkName: "component---src-pages-index-ts" */),
  "component---src-templates-blog-post-index-tsx": () => import("./../../../src/templates/BlogPost/index.tsx" /* webpackChunkName: "component---src-templates-blog-post-index-tsx" */)
}

